<template>
    <router-view></router-view>
</template>

<script>
// import debounce from 'lodash.debounce';
// import api, { ApiDefaults } from "@/utils/api_call";
//


export default {
	name: "KappInfocenter",
	components: {

	},
	data() {

		return {
			// subpages: false,
			// content: false,
			// places: false,
			// articles: false,
			// articleCategories: [],
			// placeCategories: [],
			// pageTitle: 'Infocenter',
			// contentTitle: 'Infocenter',
			// transition: ''
		};
	},
	methods: {
	},
	beforeMount() {

	},
	watch: {

	}
};
</script>

<style scoped>

.backlink {
    margin-bottom: 20px;
    position: relative;
    /*top: 90px;*/
    /*left: 30px;*/
    width: auto;
    display: inline-block;
}

.backlink .button.button--wide {
    font-size: 10px;
    padding: 3px 8px;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    background: #404040;
    color: #FFF;
    height: auto;
}

.backlink.backlink--bottom {
    position: relative;
    top: -30px;
    left: 30px;
    margin-bottom: 0;
}

.slide-left-enter-to, .slide-left-enter,
.slide-left-leave-to, .slide-left-leave,
.slide-right-enter-to, .slide-right-enter,
.slide-right-leave-to, .slide-right-leave {
    position: absolute;
    top: 0;
    padding: 107px 30px 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: inherit; /* radial-gradient(ellipse closest-side, #36404d, transparent, 200%, transparent);*/
    will-change: transform;
}

.slide-left-enter-active,
.slide-right-leave-active {
    z-index: 40;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    /*animation-duration: 35s;*/
    /*animation-timing-function: ease-out;*/
}
</style>
